<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">会社グループタイマー作成</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form-company-group-timer
              :submitType="submitType"
              :submitString="submitString"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormCompanyGroupTimer from '../../../components/FormComponents/CompanyGroupTimer'

export default {
  components: {
    FormCompanyGroupTimer,
  },
  props: {
    submitType: {
      type: String,
      default: 'post',
    },
    submitString: {
      type: String,
      default: '作成',
    },
  },
  created() {
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
